import * as React from "react"
import {Grid} from "@material-ui/core";
import MainMenu from "../MainMenu";
import "../../styles/index.scss"
import Seo from "../seo";
import AppFooter from "../AppFooter";
import {Mixpanel} from "../Mixpanel";
import {useEffect} from "react";
import '../../styles/404.scss';
import '../../styles/general.scss';

export default function Component404 ({data}) {
    const prismicContact = data.prismicContact

    const homepageData = data.prismicHomepage.data
    useEffect(() => {
        Mixpanel.track('404', {
            'url': typeof window !== "undefined"? window.location.href : "undefined"
        });

    }, [data])

    const seoData = {
        canonical: "https://www.cabinet-psiho.ro/404",
        title: 'Nu a fost gasita - Cabinet psihologic sector 6',
        description: 'Navigheaza pe site si cauta pagina dorita! Cabinet psihologic in locatiile sector 6. Avize medicale livrate prin courier in toata tara, sedinte terapie, hiphoza, clinica medicala, oriunde in tara. Alege sa lucrezi cu Roxana Simonescu, psiholog atestat',
        url: 'https://www.cabinet-psiho.ro/404',
        metas: [
        ]
    }

    return (
        <div >
            <Seo title="Despre mine" seoData={seoData}/>
            <Grid container>
                <MainMenu pageName={"404"} prismicContact={prismicContact} />

                <div className={"flex flex-row justify-center align-middle align-items-center container-404 mt-128"}>
                    <div className={"font_size_xl"}>
                        <h1>Cabinet psihologic sector 6, Roxana Simionescu psiholog atestat</h1>
                    </div>
                    <div>
                        <div>Pagina cautata nu a fost gasita</div>
                    </div>
                </div>

                <AppFooter prismicContact={prismicContact}/>
            </Grid>
        </div>
    )
}
